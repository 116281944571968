import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import cx from 'classnames';
import {withRouter} from 'react-router-dom';
import ResizeDetector from 'react-resize-detector';
import AppMain from '../../Layout/AppMain';
import Axios from 'axios';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            closedSmallerSidebar: false
        };
    }
    CheckToken = () => {
        if(this.props.history.location.pathname !== '/login'){
            Axios.get('/api/Login/TokenVerification').then(resp => {
                if (resp.data.ValidToken) {
                    this.props.setToken(resp.data);
                } else {
                    this.props.history.push('/login')
                }
            }).catch(err => {
                this.props.history.push('/login')
            })
        }
    }
    render() {
        this.CheckToken();

        let {
            colorScheme,
            enableFixedHeader,
            enableFixedSidebar,
            enableFixedFooter,
            enableClosedSidebar,
            closedSmallerSidebar,
            enableMobileMenu,
            enablePageTabsAlt,
        } = this.props;

        return (
            <ResizeDetector
                handleWidth
                render={({ width }) => (
                <Fragment>
                    <div className={cx(
                        "app-container app-theme-" + colorScheme,
                        { 'fixed-header': enableFixedHeader },
                        { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
                        { 'fixed-footer': enableFixedFooter },
                        { 'closed-sidebar': enableClosedSidebar || width < 1250 },
                        { 'closed-sidebar-mobile': closedSmallerSidebar || width < 1250 },
                        { 'sidebar-mobile-open': enableMobileMenu },
                        { 'body-tabs-shadow-btn': enablePageTabsAlt },
                    )}>
                        <AppMain />
                    </div>
                </Fragment>
            )}>
            </ResizeDetector>
        )
    }
}

const mapStateToProp = state => ({
    colorScheme: state.ThemeOptions.colorScheme,
    colorScheme: state.ThemeOptions.colorScheme,
    enableFixedHeader: state.ThemeOptions.enableFixedHeader,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableFixedFooter: state.ThemeOptions.enableFixedFooter,
    enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
})

const mapDispatchToProps = (dispatch) => {
    return ({
        setToken: (Token) => { dispatch({ type: 'Set_Token', Token }) },
    })
};

export default withRouter(connect(mapStateToProp, mapDispatchToProps)(Main))