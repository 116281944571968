import moment from 'moment'

const initState = {
    Token: {
        ValidToken: null,
        Data: {
            firstName: (sessionStorage.getItem('TSD_FN') !== null) ? JSON.parse(sessionStorage.getItem('TSD_FN')) : 'Loading',
            lastName: (sessionStorage.getItem('TSD_LN')) ? JSON.parse(sessionStorage.getItem('TSD_LN')) : 'User',
            clientName: (sessionStorage.getItem('TSD_CN')) ? JSON.parse(sessionStorage.getItem('TSD_CN')) : 'Loading Client',
            clientId: (sessionStorage.getItem('TSD_CI')) ? JSON.parse(sessionStorage.getItem('TSD_CI')) : 0,
            adminType: (sessionStorage.getItem('TSD_AT')) ? JSON.parse(sessionStorage.getItem('TSD_AT')) : 4,
        },
    },
    SelectedSite: JSON.parse(sessionStorage.getItem('BF_SessionInfo_SelectedSite')) || null,
    SiteList: JSON.parse(sessionStorage.getItem('BF_SessionInfo_SiteList')) || [],
    DateRange: {
        Start: (sessionStorage.getItem('BF_SessionInfo_DateRange_Start')) ? moment(JSON.parse(sessionStorage.getItem('BF_SessionInfo_DateRange_Start'))) : moment().subtract(7, "days").startOf("day"),
        End: (sessionStorage.getItem('BF_SessionInfo_DateRange_End')) ? moment(JSON.parse(sessionStorage.getItem('BF_SessionInfo_DateRange_End'))) : moment().endOf("day"),
    },
    ViewList: JSON.parse(sessionStorage.getItem('BF_SessionInfo_ViewList')) || [],
};

export default function reducer(state = initState, action) {
    let Token, SiteList, SelectedSite, DateRange, ViewList;
    switch (action.type) {
        case ("Set_Token"):
            Token = action.Token;
            sessionStorage.setItem('TSD_FN', JSON.stringify(Token.Data.firstName));
            sessionStorage.setItem('TSD_LN', JSON.stringify(Token.Data.lastName));
            sessionStorage.setItem('TSD_CN', JSON.stringify(Token.Data.clientName));
            sessionStorage.setItem('TSD_CI', JSON.stringify(Token.Data.clientId));
            sessionStorage.setItem('TSD_AT', JSON.stringify(Token.Data.adminType));
            return {
                ...state,
                Token,
            };
        case ("Set_SiteList"):
            SiteList = action.SiteList;
            sessionStorage.setItem('BF_SessionInfo_SiteList', JSON.stringify(SiteList));
            SelectedSite = (state.SelectedSite !== null && SiteList.indexOf(state.SelectedSite) !== -1) ? state.SelectedSite : SiteList[0];
            sessionStorage.setItem('BF_SessionInfo_SelectedSite', JSON.stringify(SelectedSite));
            return {
                ...state,
                SiteList,
                SelectedSite,
            };
        case ("Set_SelectedSite"):
            SelectedSite = action.SelectedSite;
            sessionStorage.setItem('BF_SessionInfo_SelectedSite', JSON.stringify(SelectedSite));
            return {
                ...state,
                SelectedSite,
            };
        case ("Set_DateRange"):
            DateRange = action.DateRange;
            sessionStorage.setItem('BF_SessionInfo_DateRange_Start', JSON.stringify(DateRange.Start));
            sessionStorage.setItem('BF_SessionInfo_DateRange_End', JSON.stringify(DateRange.End));
            return {
                ...state,
                DateRange,
            };
        case ("Set_ViewList"):
            ViewList = action.ViewList;
            sessionStorage.setItem('BF_SessionInfo_ViewList', JSON.stringify(ViewList));
            return {
                ...state,
                ViewList,
            };
        default:
            return state;
    };
};
